import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import { ThemeContext } from "../layouts";
import Support from "../components/Support";
import Article from "../components/Article";
import Contact from "../components/Contact";
import Headline from "../components/Article/Headline";
import Seo from "../components/Seo";
import Categories from "../components/Category";

class NewsPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          category: [],
          selectedcat: ''
        }
        this.SelectCategory = this.SelectCategory.bind(this)
    }

    SelectCategory(key) {
    }
    
    componentDidMount() {
        let category = [];
        category.push(
          {
            'title': 'Support'
          }
        )
        category.push(
          {
            'title': 'News'
          }
        )
        this.setState({
          category: category,
          selectedcat: 'News'
        })
    }
    render() {
        const {
            data: {
                supports: { edges: supports = [] }
            }
        } = this.props
        return(
            <React.Fragment>
                <Categories categories = {this.state.category} selectedcat={this.state.selectedcat} onClick={this.SelectCategory} />
                <ThemeContext.Consumer>
                    {theme => <Support posts={supports} theme={theme} />}
                </ThemeContext.Consumer>
            </React.Fragment>
        )
    }
}

NewsPage.propTypes = {
    data: PropTypes.object.isRequired
};

export default NewsPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query NewsQuery {
    supports: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//posts/[0-9]+.*--/" } }
        sort: { fields: [fields___prefix], order: DESC }
      ) {
        edges {
          node {
            excerpt
            fields {
              slug
              prefix
            }
            frontmatter {
              title
              category
              author
              cover {
                children {
                  ... on ImageSharp {
                    fluid(maxWidth: 800, maxHeight: 360) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
`;
